/*
---------------------------------------------------------------
COLORS:

Very light grey: FAF9F7;
Light grey:   F5F2EF
Medium grey:  E0DAD4
Dark grey:    B8ADAB
Black:        222020
Yellow:       FBE9B7
Pink          FFD0FA
Purple:       E4C3FF
Blue:         B6CFFF
Red:          FFBEAB
Medium Red:   FF3F07
Medium Green: 2BCA93
---------------------------------------------------------------
*/
:root {
  --color-very-light-grey: #FAF9F7;
  --color-light-grey: #F5F2EF;
  --color-medium-grey: #E0DAD4;
  --color-dark-grey: #B8ADAB;
  --color-black: #222020;
  --color-yellow: #FBE9B7;
  --color-pink: #FFD0FA;
  --color-purple: #E4C3FF;
  --color-light-purple: #F7EEFF;
  --color-very-light-purple: #F7EEFF66;
  --color-blue: #B6CFFF;
  --color-red: #FFBEAB;
  --color-green: #9AEBCF;
  --color-light-red: #FF3F0799;
  --color-intense-red: #FF3F07;
  --color-light-green: #2BCA9399;
  --color-intense-green: #2BCA93;
}


@font-face {
    font-family: 'Rubik-Light';
    font-style: normal;
    src: local('Rubik-Light'), local('Rubik Light'),
    	url(./assets/fonts/Rubik-Light.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Regular';
    font-style: normal;
    src: local('Rubik-Regular'), local('Rubik Regular'),
      url(./assets/fonts/Rubik-Regular.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Medium';
    font-style: normal;
    src: local('Rubik-Medium'), local('Rubik Medium'),
      url(./assets/fonts/Rubik-Medium.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Bold';
    font-style: normal;
    src: local('Rubik-Bold'), local('Rubik Bold'),
      url(./assets/fonts/Rubik-Bold.ttf) format('truetype')
}

body, a, h1, h2, h3, h4, p, ol, ul, li, th, td, tr,
textarea,
input[type=text], 
input[type=email], 
input[type=password], 
input[type=submit], 
input[type=button], 
button {
	font-family: 'Rubik-Light';
  font-weight: normal;
  font-size: 1em;
	margin: 0;
	padding: 0;  
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  /* Prevent browsers to do custom styling on e.g. buttons */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Remove blue background on Chrome autofill elements */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
  filter: none;
}

textarea,
input[type="text"]:required,
input[type="password"]:required,
input[type="email"]:required { 
  box-shadow: none; 
}


.TextareaAutosize textarea {
  resize: none;
}

.TextareaAutosize.invalid textarea {
  border: 1px solid var(--color-intense-red) !important;
}
.invalid-message {
  color: var(--color-intense-red);
  font-size: 0.75em;
}

.TextareaAutosize.read-only textarea {
  border: none !important;
  padding: 0;
  background: none;
  color: var(--color-black) !important;
  resize: none;
}

:focus {
  outline-color: transparent;
  outline-style: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dark-grey);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--color-dark-grey);
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-dark-grey);
}

input[type=submit] {
	cursor: pointer;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
    height: 100vh;
    display:  flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}
#root, .App {
  flex-grow: 1;
  display:  flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex:  1;
}

#portal {
  z-index: 2000 !important;
}
/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}


a {
  outline: 0;
  text-decoration: none;
}
h1 {
	font-family: 'Rubik-Medium';
	font-size: 1.4em;
	text-align: left;
	font-weight: normal;
  margin: 0vw 0 0.5vw 0;
  word-break: break-all;
}

h1 .light-text {
  font-family: 'Rubik-Light';
  font-size: 1.0em;
}
h2 {

	font-size: 1.4em;
	text-align: left;
}
h3 {
  margin: 15px 0 5px 0;
  font-size: 0.75em;
  font-family: 'Rubik-Regular';
  text-align: left;
  color:  var(--color-dark-grey);
}
h4 {
  margin: 15px 0 10px 0;
  padding: 0 0 5px 0;
  font-size: 0.75em;
  font-family: 'Rubik-Regular';
  text-align: left;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-medium-grey);
}

strong, .strong {
	font-family: 'Rubik-Medium';
}
p {
	margin: 0 auto 10px auto;
	font-size: 1em;
	overflow: hidden;
}

.no-result-text {
  font-style: italic;
  color: var(--color-dark-grey);
}

.App {
  display: flex;
  flex-direction: row;
}
/* 
---------------------------------------------------------------
Login 
--------------------------------------------------------------- 
*/
.fullscreen {
  display: flex;
  width: 100%;
  background: #fff;
}

.fullscreen-content {
  text-align: center;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20vh;
}

.fullscreen-loading-text {
}

.fullscreen-loading-logo {
  width: 15vw;
  height: 5vw;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  min-height: 100px;
  margin: 0 auto 5vh auto;
}


.fullscreen-logo {
  background-image: url('./assets/img/logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 15vw;
  height: 15vw;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  min-height: 200px;
  margin: 0 auto 5vh auto;
}
.FullscreenMessage .error-msg {
  margin-top: 2vw;
  color: var(--color-intense-red);
  display: block;
  padding: 0 4vw;
  max-width:  800px;
}
.FullscreenMessage .error-title {
  font-size: 1.2em;
  font-family: "Rubik-Medium";
}
.FullscreenMessage .error-details {
  margin-top: 0.5vw;
  font-size: 1.0em;
  text-align: left;
  word-break: break-word;
}

.fullscreen .CustomTabs {
  display:  block !important;
  flex-grow: 0;
}
.fullscreen .tab-content {
  position: initial !important;
  
  margin: 0 !important;
  margin-top: 40px !important;
}

.fullscreen .tab-list {

  display:  flex;
  flex-direction: row;
}

.fullscreen .tab-list-item {
  flex-grow: 1;
  margin: 0 !important;
}




/* 
---------------------------------------------------------------
ChangePassword 
--------------------------------------------------------------- 
*/

.ChangePassword .close-button {
  position: absolute;
  top: 0.6vw;
  right: 0.6vw;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ChangePassword .close-button-icon {
  width: 1.6vw;
  height: 1.6vw;
  border-radius: 100%;
  transform: rotate(45deg);
  cursor: pointer;
}
.ChangePassword .close-button-icon::after {
  width: 1.2vw;
  height: 0.1vw;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 0.2vw;
  top: 0.65vw;
  display: block;
}
.ChangePassword .close-button-icon::before {
  width: 1.2vw;
  height: 0.1vw;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 0.2vw;
  top: 0.75vw;
  display: block;
  transform: rotate(90deg);
}

.ChangePassword .close-button-label {
  display: block;
  font-size: 0.8em;
  margin-right: 0.5vw;
}

.ChangePassword .close-button:hover .close-button-icon {
  background: var(--color-black);
}
.ChangePassword .close-button:hover .close-button-icon::after,
.ChangePassword .close-button:hover .close-button-icon::before {
  background: #fff;
}

.ChangePassword .close-button:hover .close-button-label {
  display: block;
}

/* 
---------------------------------------------------------------
Navigation 
--------------------------------------------------------------- 
*/
.navbar {
  display: flex;
  /*width: 250px;
  margin-left: -250px;*/
  width: 75px;
  padding: 10px 0 0 0;
  min-width: 75px;
  max-width: 250px;
  flex-direction: column; 
  /*height: calc(100vh - 50px);*/
  background: var(--color-very-light-grey);
  color: #fff;
  position: relative;
  /*transition: margin-left 0.2s ease-out;*/
  transition: width 0.2s ease-out, min-width 0.2s ease-out;

}

.navbar-header {
  height: 4vh;
  min-height: 50px;
  position: static;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  padding: 0 5px 0 5px;

}
.navbar-footer {
  height: 4vh;
  min-height: 50px;
  display: flex;
  position: relative;
  bottom:  0;
  width: 100%;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  padding: 0 5px 0 5px;
}

.navbar-content {
  padding: 0 5px 0 5px;
  flex-grow:  1;
}

.navbar.show {
  /*margin-left: 0vw;*/
  width: 250px;
  min-width: 250px;
}

.navbar-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0 40px 0;
}

.navbar-group-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Rubik-Medium';
  font-size: 0.6em;
  height: 15px;
  letter-spacing: 0.1em;
  margin: 30px 0 10px 10px;
  text-transform: uppercase;
  transition: font-size 0.2s ease-out, margin 0.2s ease-out;
}

.navbar.show .navbar-group-title {
  font-size: 0.75em;
  margin: 30px 0 10px 20px;
}

.navbar .grey-text {
  color: var(--color-dark-grey);
}

.navbar .yellow-text {
  color: #FBE9B7; 
  background: linear-gradient(170deg, #FBE9B7 40%, #E4C3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar .pink-text {
  color: var(--color-pink); 
  background: linear-gradient(170deg, var(--color-pink) 30%, #E4C3FF 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-group .large {
  font-size: 2em;
}

.nav-link {
  font-size: 1.2em;
  padding: 0 0 0 20px;
  height: 50px;
  border-radius: 5px;
  color: var(--color-black);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  clear: both;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
  position: relative;
}

.nav-link .icon path.fill,
.nav-link.active .icon path {
  display: none !important;
}

.nav-link.active .icon path.fill {
  display: block !important;
}

.nav-link .icon path {
  stroke: var(--color-black) !important;
}
.nav-link:hover .icon path.fill,
.nav-link.active .icon path.fill {
  stroke: none !important;
  fill: var(--color-black) !important;
}

.nav-link.active, 
.nav-link.active:hover {
  color: var(--color-black);
  background: var(--color-light-grey);
  border-radius: 5px !important;
}

.nav-link span {
  margin-left: 15px;
  width: 0;
  opacity: 0;
  flex-grow: 2;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
}

.navbar.show .nav-link span {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.nav-link:hover {
  color: var(--color-black);
  background: var(--color-light-purple);
  /*background: linear-gradient(180deg, var(--color-pink) 0%, var(--color-purple) 100%);*/
}

.nav-link:active {
  transform: scale(0.95);
}

.nav-link .nav-link-notification {
  font-size: 0.75em !important;
  font-family: Rubik-Regular;
  color:  #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px;
  border-radius: 50px;
  background: var(--color-intense-red);
  position: absolute;
  top: 15px;
  right: 5px;
  padding: 0 3px;
}

.nav-link .nav-link-notification span {
  margin-left: initial;
  width: initial;
  opacity: initial;
  flex-grow: initial;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
}

.navbar .navbar-toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  transition: transform 0.2s ease 0.2s;
  transform: scaleX(-1);
}
.navbar.show .navbar-toggle-button {
  transform: scaleX(1);
  width: 50px;
}

.navbar .navbar-toggle-button span {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  
}

.navbar .navbar-toggle-button span:hover {
  background: var(--color-black);
}

.navbar .navbar-toggle-button span::after,
.navbar .navbar-toggle-button span::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 10px;
  top: 10px;
}
.navbar .navbar-toggle-button span::after {
  width: 15px;
  height: 2px;
}
.navbar .navbar-toggle-button span::before {
  width: 2px;
  height: 15px;
}
.navbar .navbar-toggle-button span:hover::after,
.navbar .navbar-toggle-button span:hover::before {
  background: #fff;
}

/* 
---------------------------------------------------------------
Main Content 
--------------------------------------------------------------- 
*/
.main-container {
  display: flex;
  flex-direction: column;
  /*height: 100vh;*/
  flex-grow: 2;
}

.main-content-header {
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 0 15px 0 15px;
}

.main-content-header-wrap {
  position: static;
}
 
.main-content-header .logo {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8em;
}
.main-content-header .menu {
  display: flex;
  position: absolute;
  right: 15px;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  font-size: 0.9em;
}

.main-content-header .logo {
  letter-spacing: 0.3em;
  font-size: 1.3em;
  flex-grow: 2;
  text-align: center;
}

.main-content-header .menu .menu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
}
.main-content-header .menu .menu-item:hover {
  
}

.main-content-header .menu .menu-item:hover .menu-content {
  display: block;
}

.main-content-header .menu .menu-button {
  text-decoration: none;
  cursor: pointer;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}

.main-content-header .menu .menu-item.last {
  padding-right: 0px
}

.main-content-header .menu-item .menu-button:hover {
  background: transparent;
}
.main-content-header .menu .menu-button .user-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.main-content-header .menu .menu-button .user-name::after,
.main-content-header .menu .menu-item:hover .user-name::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-top: 5px solid var(--color-black);
  border-bottom: none;
  margin: 0px 0 3px 10px;
}

.main-content-header .menu .menu-button .user-name::after {
  border-top: 5px solid transparent;
}

.main-content-header .menu .menu-content {
  border: 1px solid #fff;
  position: absolute;
  z-index: 1000;
  right: 0;
  top: calc(100% + 1px);
  background: #fff;
  display: none;
  overflow: hidden;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
}

.main-content-header .menu .menu-link {
  color: var(--color-black);
  display: block;
  text-decoration: none;
  padding: 0 20px 0 15px;
  white-space: nowrap;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 50px;
}

.main-content-header .menu .menu-link .icon {
  margin-right: 10px;
}

.main-content-header .menu .menu-link:active {
  transform: scale(0.95);
}

.main-content-header .menu .menu-link .icon path {
  stroke: var(--color-black) !important;
}
.main-content-header .menu .menu-link .icon path.fill {
  display: none;
}

.main-content-header .menu .menu-link span {
  display: flex;
  flex-grow: 2;
}

.main-content-header .menu .menu-link:hover .icon path {
  stroke: rgb(255,255,255) !important;
}

.main-content-header .menu .menu-link:hover {
  background: var(--color-black);
  color: #fff;
  border-bottom: 1px solid transparent;
  border-radius: 3px;
}

.main-content-header .menu .about-icon {
  border: 1px solid var(--color-dark-grey);
  color: var(--color-dark-grey);
  border-radius: 50%;
  display: flex;
  width: 21px;
  height: 21px;
  font-size: 1em;
  font-family: 'Rubik-Medium';
  justify-content: center;
  align-items: center;
}

.main-content-header .menu-item:hover .about-icon {
  background: var(--color-black);
  color: #fff;
  border: 1px solid var(--color-black);
}

.main-content-header .about-logo {
  background-image: url('./assets/img/logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 30px;
  margin: 20px 20px 0 20px;
}

.main-content-header .about-version {
  color: var(--color-dark-grey);
  margin: 15px;
  text-align: center;
}

.main-content-header .about-copyright {
  color: var(--color-dark-grey);
  margin: 15px;
  text-align: center;
  font-size: 0.9em;
}

.main-content-header .about-copyright a {
  color: var(--color-dark-grey);
}

.main-content-header .about-copyright a:hover {
  color: var(--color-black);
}

.main-content-header .about-copyright a:hover {
  color: var(--color-black);
}

.user-profile {
  display:  flex;
  flex-direction: row;
  align-items: center;
}

.user-profile .Photo {
  margin: 10px;
}
.user-profile.slim .Photo {
  margin: 0 8px 0 0;
}
.user-profile-info {
  padding: 10px 20px 10px 10px;
}
.user-profile.slim .user-profile-info {
  padding: 8px;
}
.user-profile-name {
  font-family: 'Rubik-Medium';
  white-space: nowrap;
}
.user-profile-username {
  font-size: 0.9em;
  color: var(--color-dark-grey);
}
.main-content-header .horizontal {
  display: flex;
  flex-direction: row;
}
.main-content-header .horizontal .menu-link {
  flex-grow:  1;
}



.main-content {
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-content .narrow-margin {
  /*margin: 15px 15px;*/
  margin: 0;
}
.main-content .wide-margin {
  margin: 15px 30px 0px 30px;
  display: flex;

  width: 100%;
  flex-direction: column;
  align-items: stretch;
}

.main-content .columns {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
}


.MainColumn {
  width: 30vw;
  background: #fff;
  border-right: 1px solid var(--color-light-grey);
  min-width: 400px;
  max-width: 500px;
  flex-grow: 1;
  transition: width 0.2s ease-out, max-width 0.2s ease-out, min-width 0.2s ease-out, padding 0.2s ease-out;
  position: relative;
}
.main-content .columns .column {
  padding: 10px 20px 0px 20px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.main-content .center-column {
  max-width: 600px;
  flex-grow: 2;
  overflow-y: auto;
}

.MainColumn.column {
  padding: 20px 20px 0px 30px !important;
}

.MainColumn .button-back {
  position: absolute;
  z-index: 10;
  top: 7px;
  right: -10px;
  border: 0px solid var(--color-very-light-grey);
  border-radius: 50px;
  background:  none;
  height: 35px;
  padding: 5px 13px;
  margin: 0 20px 0px 0;
  text-transform: uppercase;
  font-size:  0.8em;
  cursor: pointer;
  display:  flex;
  flex-direction: row;
  align-items: center;
  font-family: "Rubik-Light";
  transition: background 0.1s ease-out, color 0.1s ease-out, border-color 0.1s ease-out, right 0.2s ease-out;
}
.MainColumn .button-back:hover {
  border: 0px solid var(--color-black);
  background:  var(--color-black);
  color:  #fff;
}
.MainColumn .button-back:active {
  transform:  scale(0.95);
}
.MainColumn .button-back .button-back-arrow {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 3px 0 0;
}

.MainColumn .button-back .button-back-arrow::after,
.MainColumn .button-back .button-back-arrow::before {
  content: '';
  background: var(--color-black);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.MainColumn .button-back .button-back-arrow::after {
  width: 10px;
  height: 2px;
}
.MainColumn .button-back .button-back-arrow::before {
  width: 2px;
  height: 10px;
}
.MainColumn .button-back:hover .button-back-arrow::after,
.MainColumn .button-back:hover .button-back-arrow::before {
  background: #fff;
}
.MainColumn .button-back .button-back-label {
  overflow: hidden;
  text-align: left;
  transition: opacity 0.2s ease-out;
}
.MainColumn.hide .button-back .button-back-label {
  width: 0;
  opacity: 0;
}
.MainColumn.hide .button-back {
  width: 35px;
  right: -12.5px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.MainColumn.hide .button-back .button-back-arrow {
  margin:  2px 0 0 2px;
}


.MainColumn .button-toggle-main-column {
  position: absolute;
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  right: 0px;
  transition: right 0.2s ease-out, transform 0.2s ease 0.2s;
  z-index: 10;
  transform: scaleX(1);
}

.MainColumn.hide .button-toggle-main-column {
  transform: scaleX(-1);
}

.MainColumn.hide .button-toggle-main-column {
  right: calc(50% - 15px);
}

.MainColumn .button-toggle-main-column span {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  
}

.MainColumn .button-toggle-main-column:hover span {
  background: var(--color-black) !important;
}

.MainColumn .button-toggle-main-column span::after,
.MainColumn .button-toggle-main-column span::before {
  content: '';
  background: var(--color-medium-grey);
  position: absolute;
  border-radius: 2px;
  left: 10px;
  top: 10px;
}
.MainColumn .button-toggle-main-column span::after {
  width: 15px;
  height: 2px;
}
.MainColumn .button-toggle-main-column span::before {
  width: 2px;
  height: 15px;
}
.MainColumn .button-toggle-main-column:hover span::after,
.MainColumn .button-toggle-main-column:hover span::before {
  background: #fff;
}

.MainColumn.hide {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  padding-left: 24px !important;
  padding-right: 0px;
  padding-top: 20px;
  cursor: pointer;
  overflow: hidden;
}

.ObjectDetails .object-type,
.ObjectDetails .object-subtitle,
.ObjectDetails .object-secondary-title,
.ObjectDetails .object-content,
.MainColumn .main-toolbar {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.2s, transform 0s ease 0.2s;
  visibility: visible;
  transform: scale(1);
  white-space: pre-wrap;
}

.ObjectDetails .object-secondary-title{
  line-height: 1.5em;
}

.MainColumn.hide .object-title {
  transform: rotate(90deg);
  /*width: calc(100vh - 100px);*/
}
.MainColumn.hide .object-title h1 {
  font-size: 1em;
  margin: 0;
  text-align:  left !important;
}
.MainColumn.hide .object-type,
.MainColumn.hide .object-subtitle,
.MainColumn.hide .object-secondary-title,
.MainColumn.hide .object-content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease-out, visibility 0s ease 0.2s, transform 0s ease 0s;
  transform: scale(0);
}

.MainColumn.hide .Photo {
  margin: 35px 0 5px -16px;
}
.MainColumn.hide .Photo,
.MainColumn.hide .Photo .photo-img,
.MainColumn.hide .Photo .photo-loader {
  width: 32px !important;
  height: 32px !important
}


.MainColumn.hide .main-toolbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease-out, visibility 0s ease 0.2s;
  white-space: nowrap;
  word-break: none;
}

.ObjectDetails {
  padding: 10px 0px 20px 0;
  background: #fff;
  border-radius: 5px;
  z-index:  1;

}

.ObjectDetails .object-type {
  white-space: nowrap;
  font-size: 0.7em;
  text-transform: uppercase;
  font-family: 'Rubik-Regular' !important;
  font-weight: normal;
  margin-bottom: 5px;
}

.ObjectDetails .object-title {
  transition: transform 0.2s ease-out, width 0.2s ease-out;
  transform-origin: center left;
  transform: rotate(0deg);
  width: 100%;
}
.ObjectDetails .object-secondary-title {
  margin: 0 0 5px 0;
}
.ObjectDetails .object-title h1 {
  transition: font-size 0.2s ease-out;
}

.ObjectDetails.center .object-subtitle {
  justify-content: center;
}

.ObjectDetails .object-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 25px 0;
}

.ObjectDetails .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 15px 0 0;
  min-width: 1.0em;
}
.ObjectDetails .object-subtitle-icon .icon {
  margin: 0 5px 0 0;
}
.ObjectDetails .object-subtitle-icon:nth-last-child(2) .icon {
  margin: 0 15px 0 0;
}
.ObjectDetails .object-subtitle-text {
  font-size: 0.9em;
  flex-grow: 2;
  flex-direction: row;
  margin: 0 0 0 0;
  color: var(--color-dark-grey);
  display: flex;
  flex-wrap: wrap;
}

.ObjectDetails.center .object-title h1 {
  text-align:  center;
}

.ObjectDetails.center .object-subtitle-text,
.ObjectDetails.center .object-secondary-title {
  text-align: center;
  justify-content: center;
}

.ObjectDetails .Photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100% !important;
  margin: 10px 0 20px 0;
}

.ObjectDetails .Photo,
.ObjectDetails .Photo .photo-img,
.ObjectDetails .Photo .photo-loader {
  transition: width 0.2s ease-out, height 0.2s ease-out, margin 0.2s ease-out;
}

.ObjectDetails .Photo .photo-img {
  position: relative;
}
.ObjectDetails .Photo .photo-img .error-notification {
  display: none;
  left: initial;
  right: 0px;
}

.ObjectDetails .object-content {
  margin: 0 0 25px 0;
}

.ObjectDetails .contact-info-container {
  margin-bottom: 25px;
}

.ObjectDetails .contact-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.ObjectDetails .contact-info a {
  align-items: center;
}
.ObjectDetails .contact-info a .icon {
  min-width: 26px;
}
.ObjectDetails .error-notification {
  width: 8px;
  height: 8px;
  background: var(--color-intense-red);
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 20px;
}

.ObjectDetails .object-content .contact-info:last-child {
  margin-bottom: 25px !important;
}

.ObjectDetails .link {
  display: inline-flex;
  flex-direction: row;
}

.ObjectDetails .link:hover {
  /*color: #E4C3FF;*/
  color: #DAC3FF;
}


.dark-background {
  background-color: var(--color-light-grey);
}

.display-linebreak {
  white-space: pre-wrap;
  word-break: break-all;
  display: flex;
  flex-direction: row;
  align-items:  center;
}

.code {
  font-family: "Courier New", Courier, monospace;
  font-size: 0.85em;
}

/* 
---------------------------------------------------------------
NotFound
--------------------------------------------------------------- 
*/

.page-not-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10vh;
}

.page-not-found-text {
  font-size: 1.5em;
  margin: 40px 0 0px 0;
}

.page-not-found-smalltext {
  font-size: 0.9em;
  margin: 20px 0 60px 0;
}


/* 
---------------------------------------------------------------
Home
--------------------------------------------------------------- 
*/
.Home {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Home .center-column {
  align-items: center;
}

.center-column .row {
  margin: 0 0 40px 0;
}


/* 
---------------------------------------------------------------
CustomTabs
--------------------------------------------------------------- 
*/
.CustomTabs {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.CustomTabs .tab-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.CustomTabs .result-count {
  color: var(--color-dark-grey);
  font-size: 0.9em;
  margin: 15px 0 10px 15px;
}

.CustomTabs .tab-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  padding: 1vw 0 0 0;
  background: var(--color-light-grey);
  border-bottom: 1px solid var(--color-medium-grey);
}

.CustomTabs.left .tab-list {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.CustomTabs .tab-list-item {
  margin: 0 0 0 0;
  color: var(--color-black);
  font-family: 'Rubik-Regular';
  font-size: 0.8em;
  padding: 7px 5px;  
  cursor: pointer;
}

.CustomTabs .tab-list-item:hover .label,
.CustomTabs .tab-list-item:focus .label {
  color: var(--color-black);
}
.CustomTabs .tab-list-item:nth-child(n+2) {
  margin: 0 0 0 20px;
}
.CustomTabs .tab-list-item.active,
.CustomTabs .tab-list-item:hover,
.CustomTabs .tab-list-item:focus,
.CustomTabs .tab-list-item.active.disabled:hover,
.CustomTabs .tab-list-item.active.disabled:focus {
  border-bottom: 2px solid var(--color-black);
}
.CustomTabs .tab-list-item.active .label,
.CustomTabs .tab-list-item.has-results .label {
  color: var(--color-black);
}
.CustomTabs .tab-list-item .label {
  text-transform: uppercase; 
  font-family: 'Rubik-Medium';
  margin: 0 0 0 0 ;
  white-space: nowrap;
}
.CustomTabs .tab-list-item .label-result-count {
  margin: 0 0 0 5px;
  color: var(--color-dark-grey);
}

.CustomTabs .tab-list-item.disabled,
.CustomTabs .tab-list-item.active.disabled .label,
.CustomTabs .tab-list-item.disabled:hover .label,
.CustomTabs .tab-list-item.disabled:focus .label {
  cursor: default;
  color: var(--color-dark-grey) !important;
  background: none !important;
}

.CustomTabs .tab-list-item.disabled:hover,
.CustomTabs .tab-list-item.disabled:focus {
  border-bottom: none;
}


/* Slim appearance */
.CustomTabs.slim .tab-list {
  background: transparent;
  padding: 0;
}

/* Buttons appearance */
.CustomTabs.buttons .tab-list {
  border: none;
}

.CustomTabs.buttons .tab-list-item {
  color: var(--color-dark-grey);
  padding: 7px 13px;  
  border-radius: 100px;
}
.CustomTabs.buttons .tab-list-item .label {
  color: var(--color-dark-grey);
  font-size: 0.9em;
}

.CustomTabs.buttons .tab-list-item:nth-child(n+2) {
  margin: 0 0 0 3px;
}
.CustomTabs.buttons .tab-list-item:hover ,
.CustomTabs.buttons .tab-list-item:focus {
  border-bottom: none;
  background: #E0DAD499;
}
.CustomTabs.buttons .tab-list-item:hover .label ,
.CustomTabs.buttons .tab-list-item:focus .label {
  color: var(--color-black);
}
.CustomTabs.buttons .tab-list-item.has-results .label {
  color: var(--color-black);
}
.CustomTabs.buttons .tab-list-item.active {
  background: var(--color-black);
  border-bottom: none;
}
.CustomTabs.buttons .tab-list-item.active .label {
  color: #fff;
}

.CustomTabs .tab-content {
  margin: 10px auto 0 auto;
  display:  flex;
  flex-direction: column;
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
}

.tab-content-empty-text {
  margin: 10px 0px;
  font-style: italic;
  color: var(--color-dark-grey);
}

.CustomTabs .tab-content-wrapper {
  margin: 0px auto 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  relative;
  margin-bottom:  10px;
}

.CustomTabs.vw-60 .tab-content {
  max-width: 60vw;
}
.CustomTabs.vw-20,
.CustomTabs.vw-20 .tab-content {
  width: 300px;
}



.CustomTabs.show-result-count .tab-content {
  margin: 0 auto;
}


.CustomTabs .search-result {
  padding: 10px 15px 15px 15px;
  border-radius: 3px;
}
.CustomTabs .search-result:hover,
.CustomTabs .search-result-link:focus .search-result {
  background: #fff;
}
.CustomTabs .search-result:active {
  transform: scaleX(0.99) scaleY(0.95);
}


.CustomTabs .search-result-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Rubik-Medium';
  margin: 0 0 0.25vw 0;
}

.CustomTabs .search-result-title .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 15px 0 0;
}

.CustomTabs .search-result-body {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.CustomTabs .search-result-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.25vw 0;

}

.CustomTabs .search-result-subtitle .connection_string {
  font-size: 0.9em;
  flex-grow: 2;
  margin: 0 0 0 0;
  color: var(--color-dark-grey);
  display: flex;
}

.color-medium-green {
  color: var(--color-intense-green); /* green */
}
.color-medium-red {
  color: var(--color-intense-red); /* red */
}

/* 
---------------------------------------------------------------
Breadcrumbs
--------------------------------------------------------------- 
*/
.Breadcrumbs {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Breadcrumbs .divider {
  color: var(--color-dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Breadcrumbs .vertical-spacer {
  background: var(--color-medium-grey);
  width: 0px;
  height: 35px;
  margin: 0 15px 0 0;
}

.app-breadcrumbs {
  display: flex;
}

.Breadcrumbs .breadcrumb-item-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.9em;
}
.Breadcrumbs .breadcrumb-item {
  margin: 5px;
  display: flex;
  flex-direction: row;
  text-transform: capitalize; 
}
.Breadcrumbs .icon-kayenta {
  margin-left: 25px;
}
.Breadcrumbs .apps-link {
  padding: 0 5px;
}
.Breadcrumbs .apps-link .breadcrumb-item:active {
  transform: scale(0.9);
}
.Breadcrumbs .breadcrumb-item span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Breadcrumbs .default-breadcrumb {
  margin: 10px 0 12px 20px;
  font-size: 1.5em;
}
.Breadcrumbs .breadcrumb-item-text {
  text-overflow: ellipsis;
  display:  block !important;
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
}

.Breadcrumbs .link {
  text-decoration: none;
  font-family: 'Rubik-Regular';
}
.Breadcrumbs .link:hover {
  color: #E4C3FF;
}
.Breadcrumbs .first-in-app {
  font-family: 'Rubik-Medium';
  font-size: 1.5em;
}

/* 
---------------------------------------------------------------
message-container
--------------------------------------------------------------- 
*/
.message-container {
  border: 1px solid #9AEBCF99;
  border-radius: 3px;
  padding: 15px;
  color: var(--color-intense-green);
  width: 25vw;
  margin-left: calc(50% - 12.5vw);
  position: absolute;
  top: -1000px;
  display: flex;
  flex-direction: row;
  opacity: 0;
  background-color: #CDF5E7;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out, top 0s ease 0.25s;
  transform: scale(0.9);
  z-index: 20;
}

.message-container.show {
  opacity: 1;
  top: 10px;
  transform: scale(1);
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
.message-container.error {
  border: 1px solid var(--color-red);
  background-color: #FFDAD0;
  color: var(--color-intense-red);
}
.message-container .message-text-container {
  flex-grow: 1;
  flex-basis: max-content;
  display:  flex;
  flex-direction: column;
  align-items: flex-start;
}

.message-container .message-text,
.message-container .message-details {
  flex-wrap: wrap;
  word-break:break-word;
  white-space: pre-wrap;
}
.message-container .message-text {
  font-family: Rubik-Regular;
}
.message-container .message-details {
  margin-top:  10px;
  font-size: 0.9em;
}

.message-container .message-details-toggle-button {
  font-size:  0.8em;
  margin-top:  10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
}

.message-container .message-details-toggle-button span {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 7px;
  height: 7px; 
  margin: 0 3px 2px 5px;
  transition: transform 0.1s ease-out;
}
.message-container .message-details-toggle-button:hover {
  color: var(--color-black);
}
.message-container .message-details-toggle-button:hover span::after,
.message-container .message-details-toggle-button:hover span::before {
  background: var(--color-black);
}

.message-container .message-details-toggle-button.show span {
  transform: rotate(225deg);
}

.message-container  .message-details-toggle-button span::after,
.message-container  .message-details-toggle-button span::before {
  content: '';
  background: var(--color-intense-green);
  border-radius: 2px;
  left: 0px;
  top: 0px;
  position: absolute;
}
.message-container.error  .message-details-toggle-button span::after,
.message-container.error  .message-details-toggle-button span::before { 
  background: var(--color-intense-red);
}
.message-container .message-details-toggle-button span::after {
  width: 7px;
  height: 1px;
}
.message-container .message-details-toggle-button span::before {
  width: 1px;
  height: 7px;
}


.message-container .close-button {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 100%;
  transform: rotate(45deg);
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.message-container .close-button::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-light-green);
  position: relative;
  left: 2px;
  top: 7px;
  display: block;
}
.message-container .close-button::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-light-green);
  position: relative;
  left: 2px;
  top: 9px;
  display: block;
  transform: rotate(90deg);
}
.message-container.error .close-button::before,
.message-container.error .close-button::after {
  background: var(--color-light-red);
}

.message-container .close-button:hover {
  background: var(--color-black);
}
.message-container .close-button:hover::after {
  background: #fff;
}
.message-container .close-button:hover::before {
  background: #fff;
}


/* 
---------------------------------------------------------------
main-toolbar
--------------------------------------------------------------- 
*/
.main-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0;
  height: 40px;
}
.main-toolbar.align-right {
  justify-content: flex-end;
}
.main-toolbar .selected-items-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.main-toolbar-title {
  font-size: 1.5em;
  font-family: 'Rubik-Medium';
  margin: 0 30px 0 0;
}
.main-toolbar-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  margin: 0 10px 0 30px;
}

.DataTableToolbar .main-toolbar-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  margin: 0 10px 0 0px;
}
.DataTableToolbar .main-button {
  margin-right: 30px !important;
}

.main-toolbar .filter-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DataTableToolbar .CustomInput input {
  height: 35px;
  padding: 0 15px;
  border: 1px solid var(--color-medium-grey);
  width: 200px;
}
.DataTableToolbar .CustomInput:hover input {
  border: 1px solid var(--color-black);
}




.main-toolbar .morph-button-wrapper {
  display: flex;
}

.main-toolbar .morph-button-toggle {
  transition: opacity 0s ease 0.15s;
  margin-left: 0 !important;
}
.main-toolbar .morph-button-wrapper.show {

}
.main-toolbar .morph-button-wrapper .morph-button-content {
  width: 0px;
  transition: width 0.15s ease, opacity 0.05s ease 0.05s; /* hide input field */
  pointer-events: none;
  opacity: 0;
}
.main-toolbar .morph-button-wrapper.show .morph-button-content {
  width: 25vw;
  max-width: 500px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: width 0.15s ease, opacity 0.1s ease 0.05s; /* show input field */

}
.main-toolbar .morph-button-wrapper.show .morph-button-toggle {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease;
  
}

.main-toolbar .morph-button-wrapper .CustomSelect .select-container,
.main-toolbar .morph-button-wrapper .CustomSelect .select-options,
.main-toolbar .morph-button-wrapper .CustomSelect .select-button-container,
.main-toolbar .CustomCheckbox .checkmark {
  background: #fff;
}

.form-block.inline {
  display: flex;
  flex-direction: row;
  /*width: 300px;*/
}

.form-block.inline label {
  margin-bottom: 0;
}




/* 
---------------------------------------------------------------
DataTable 
--------------------------------------------------------------- 
*/

.DataTableWrapper {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  margin-top: 10px;
}

.DataTable {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  width: 100%;
  margin-right: 20px;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}
.DataTable th, 
.DataTable td {
  padding: 10px 10px;
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataTable th {
  font-family: 'Rubik-Medium' !important;
  font-size: 0.75em;
  border-bottom: 2px solid var(--color-light-grey);
  padding: 1px 10px 10px 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  white-space: nowrap;
  vertical-align: center;
}

.DataTable th.checkbox {
  padding-bottom: 4px;
}


.DataTable td {
  border-bottom: 1px solid rgba(184,173,171,0.2);
}

.DataTable .checkbox {
 width: 20px;
 vertical-align: middle !important;
}
.DataTable .CustomCheckbox {
  display: flex;
}

.DataTable .CustomCheckbox .checkmark {
  background: none;
}

.DataTable .row:hover {
  background: var(--color-very-light-purple);
}

.DataTable .row.selected {
  background: var(--color-light-purple);
}

.DataTable th.sortable {
  cursor: pointer;
}

.DataTable .loading-dummy-row {
  height: 100px;
  text-align: center;
  vertical-align: top;
}
.DataTable .loading-dummy-row td {
  font-family: Rubik-Medium;
  padding:  20px 0 0 0;
}


/* Sort arrow */
.DataTable th.sortable::after,
.DataTable th.sortable.asc::after, 
.DataTable th.sortable:hover::after,
.DataTable th.sortable.desc:hover::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-bottom: 5px solid var(--color-black);
  border-top: none;
  margin: 10px 0 3px 10px;
}
.DataTable th.sortable::after {
  border-bottom: 5px solid transparent;
}
.DataTable th.sortable.desc::after,
.DataTable th.sortable.desc:hover::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-top: 5px solid var(--color-black);
  border-bottom: none;
  margin: 10px 0 3px 10px;
}

.DataTable .left {
 text-align: left;
}
.DataTable .right {
 text-align: right;
}
.DataTable .center {
 text-align: center;
}

.DataTable .no-right-margin {
 padding-right: 0 !important;
}
.DataTable .no-right-margin .icon {
 margin-right: 0;
}

.DataTable .bold {
  font-family: 'Rubik-Regular';
}

.DataTable .button {
  height:  30px;
}

.DataTable .button span {
  font-size: 0.9em;
  height: 30px;
}

.DataTable .small-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
}

.DataTable .link {
  text-decoration: none;
  font-family: inherit;
}

.DataTable .type-photo {
  padding: 0 0 0 10px !important;
  width: 28px;
}
.DataTable .photo-img {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.DataTable .photo-img.placeholder {
  border-radius: 0%;
}


.DataTable .type-button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.DataTable .type-icon {
  width: 30px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.DataTable .type-boolean {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.DataTable .link:hover {
  /*color: #E4C3FF;*/
  color: #DAC3FF;
}

.DataTableWrapper .empty-text {
  margin: 40px 30px;
  font-style: italic;
  color: var(--color-dark-grey);
}

.DataTable .chart-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DataTable .chart-container .bar {
  height: 2px;
  background-color: var(--color-black);
}
.DataTable .chart-container .bar-bg {
  height: 2px;
  background-color: var(--color-medium-grey);
}

.pill {
  font-size: 0.65em;
  font-family: 'Rubik-Regular';
  text-transform: uppercase;
  border: 1px solid var(--color-medium-grey);
  border-radius: 50px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
}

.pill-wide {
  min-width: 90px;
}

.pill-full-red {
  border: 1px solid var(--color-intense-red);
  background: var(--color-intense-red);
  color: #fff;
}
.pill-full-green {
  font-family: Rubik-Medium;
  border: 1px solid var(--color-green);
  background: var(--color-green);
  color: #fff;
}

.pill-red {
  border: 1px solid var(--color-red);
  color: var(--color-intense-red);
}

.pill-green {
  border: 1px solid var(--color-green);
  color: var(--color-intense-green);
}

.pill-grey {
  color: var(--color-dark-grey);
}

.DataTable .chart {
  max-width: 10vw;
  min-width: 5vw;
  padding-left: 0;
}

.DataTable .last-column {
  padding: 0px !important;
}

.DataTable .last-column.width-1 {
  width: 0px;
}
.DataTable .last-column.width-1 {
  width: 30px;
}
.DataTable .last-column.width-2 {
  width: 60px;
}
.DataTable .last-column.width-3 {
  width: 90px;
}

.DataTable .last-column .buttons {
  display: none;
}

.DataTable .row:hover .last-column .buttons {
  display: flex;
  flex-direction: row;
}

.DataTable .row:hover .last-column .buttons div:nth-child(n+2) {
  margin-left: 5px;
}

.pagination {
  display:  flex;
  flex-direction:  row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid rgba(184,173,171,0.3);
}
.pagination-page-button {
  display:  flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 1px;
  cursor: pointer;
  min-width: 30px;
  flex-grow: 0;
}
.pagination-page-buttons {
  display:  flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination-page-button:hover, .pagination-page-button.active {
  background: var(--color-black);
  border-radius: 50px;
  color: #FFF;
}

.pagination-page-button:active {
  transform: scale(0.95);
}

.pagination-page-button.step-button {
  padding: 5px 10px;
  margin: 0 15px;
  font-size:  0.8em;
  text-transform: uppercase;
  min-width: 70px;
}

.pagination-page-button.step-button.prev .button-arrow {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 3px 0 0;
}
.pagination-page-button.step-button.next .button-arrow {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 0 0 3px;
}

.pagination-page-button.step-button .button-arrow::after,
.pagination-page-button.step-button .button-arrow::before {
  content: '';
  background: var(--color-black);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
}
.pagination-page-button.step-button .button-arrow::after {
  width: 10px;
  height: 2px;
}
.pagination-page-button.step-button .button-arrow::before {
  width: 2px;
  height: 10px;
}
.pagination-page-button.step-button:hover .button-arrow::after,
.pagination-page-button.step-button:hover .button-arrow::before {
  background: #fff;
}


/* 
---------------------------------------------------------------
Side Dialog 
--------------------------------------------------------------- 
*/

.SideDialog {
  position: absolute;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: column;
  right: -450px;
  width: 450px;
  height: 100%;
  background: #fff;
  border-left: 1px solid var(--color-medium-grey);
  transition: right 0.1s ease-out;
}
.side-dialog-container {
  overflow-y: auto;
  padding: 25px 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.SideDialog.show {
  right: 0;
}

.SideDialog .side-dialog-header {
 display: flex;
 flex-direction: row;
 justify-content: space-between; 
 margin: 0 0 15px 0;
}

.SideDialog .side-dialog-content {
 display: flex;
 flex-grow: 2;
 flex-direction: column;
 justify-content: space-between; 
}

.SideDialog h2 {
  font-family: 'Rubik-Medium';
  font-size: 1.5em;
}

.SideDialog .close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(45deg);
  position: relative;
  cursor: pointer;
}
.SideDialog .close-button::after {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
}
.SideDialog .close-button::before {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 14px;
  display: block;
  transform: rotate(90deg);
}
.SideDialog .close-button:hover {
  background: var(--color-black);
}
.SideDialog .close-button:hover::after {
  background: #fff;
}
.SideDialog .close-button:hover::before {
  background: #fff;
}

.form .CustomInput input {
  background: var(--color-very-light-grey);
}


.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
}

.form form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
}

.form-content {
  width:  390px;
  margin: 15px 0 0 0;
}

.form-content .CustomSelect .select-container,
.form-content .CustomSelect .select-options,
.form-content .CustomSelect .select-button-container,
.form-content .CustomSelectButtons .select-options,
.form-content .CustomCheckbox .checkmark  {
  background: var(--color-very-light-grey);
}


.form-content .CustomInput input {
  border: 1px solid var(--color-light-grey);
}

.form-content .form-block > div:nth-child(n+2) {
  margin: 5px 0 0 0;
}

.form-block {
  margin: 10px 0 10px 0;
}

.form-block.vertical {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.form-block.horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form .horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form .horizontal .icon {
  margin: 0 15px 0 0;
}

.form-block.vertical * {
  /*margin: 0 0 0.5vw 0;*/
}
.form-block.horizontal > * {
  margin: 0 10px 0 0 !important;
}

.form-text {
  padding: 0 15px 10px 15px;
}

.form-light-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  margin: 0.5vw 0 0 0;
  cursor: default;
  word-break: break-all;
}

.form .link:hover {
  /*color: #E4C3FF;*/
  color: #DAC3FF;
}
/*
.form textarea,
.form input[type="text"],
.form input[type="email"],
.form input[type="password"] {
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 10px 15px 10px 15px;
  clear: both;
  border-radius: 3px;
  width: 100%;
}*/

.form .form-error p {
  color: var(--color-intense-red);
  font-size: 0.9em;
}
.form textarea {
  min-height: 10vh;
  max-height: 30vh;
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 10px 15px 10px 15px;
  border-radius: 3px;
  width: 100%;
}

.form textarea:focus,
.form textarea:hover {
  border: solid 1px var(--color-black);
}
.form textarea:disabled {
  color: var(--color-dark-grey);
}
.form textarea:disabled:hover {
  border: solid 1px var(--color-light-grey);
}

.form label {
  margin: 0 0 0.5vw 0;
  display: block;
  font-size: 0.75em;
  font-family: 'Rubik-Medium';
}

.form .CustomCheckbox {
  margin: 0 0 0vw 0 !important;
}

.form label .CustomCheckbox {
  font-size: 1em;
}

.connection-test-cancel {
  cursor: pointer;
  flex-grow: 2;
  text-align: right;
}

.connection-test-cancel:hover {
  color: var(--color-black);
}

.connection-test-result {
  margin: 0.5vw 0 0 0;
  font-size: 0.8em;
  color: var(--color-dark-grey);
  display: flex;
  flex-direction: row;
  width:  100%;
  white-space: pre-line;
}
.connection-test-result.failed {
  color: var(--color-intense-red);
}

.connection-test-result-icon.success {
  width: 0.8vw;
  height: 0.8vw;
  margin: 0 0.3vw 0 0.5vw;
  position: relative;
  transform: rotate(35deg);
}
.connection-test-result-icon.success::before {
  position: absolute;
  right: 0.3vw;
  top: 0;
  content: '';
  display: block;
  width: 0.15vw;
  height: 0.8vw;
  background: var(--color-intense-green);
  border-radius: 1px;
}
.connection-test-result-icon.success::after {
  position: absolute;
  right: 0.3vw;
  top: 0.7vw;
  content: '';
  display: block;
  width: 0.5vw;
  height: 0.15vw;
  background: var(--color-intense-green);
  border-radius: 1px;
}
/*Medium Red:   FF3F07
Medium Green: 2BCA93*/

/* 
---------------------------------------------------------------
Modal Dialog 
--------------------------------------------------------------- 
*/

.App.modal-dialog-is-shown {
  filter: blur(2px);
}

.App {
  transition: filter 0.2s ease-out;
}

.modal-underlay { 
  display:  flex;
  justify-content: center;
  align-items: center;
  cursor: default !important;
  z-index: 1 !important;
  background: rgba(255,255,255,0.5) !important;
}

.modal-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  max-height: 80vh;
  max-width: 80vw;
  min-width: 400px;
  /*overflow: hidden;*/
  display:  flex;
  flex-direction:  column;
}

.modal-container-header {
  padding: 15px 20px;
  font-family: 'Rubik-Medium';
  /*background: var(--color-very-light-grey);*/
  border-radius: 10px 10px 0px 0px;
  /*border-bottom: 1px solid var(--color-light-grey);*/
}

.modal-container-body {
  padding: 0 20px 15px 20px;
  overflow-y: auto;
}

.modal-container-body-code {
  white-space: pre-wrap !important;
}
.modal-container-body-text {
  max-width: 500px;
  white-space: pre-wrap !important;
}
.modal-container-body-text.wide {
  max-width: 1000px;
  white-space: pre-wrap !important;
}

.modal-container-footer {
  padding: 10px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.modal-container-footer .footer-item:nth-child(n+1) {
  margin-left: 5px;
}

.modal-container-body ul {
  margin: 15px 0 0 0;
}
.modal-container-body li {
  list-style-type: none;
  margin-top: 0.5vw;
}

/* 
---------------------------------------------------------------
FormTabs
--------------------------------------------------------------- 
*/
.FormTabs .tab-list {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
}
.FormTabs .tab-list-item {
  flex-grow: 1;
  font-family: 'Rubik-Medium';
  font-size: 0.8em;
  color: var(--color-dark-grey);
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid var(--color-medium-grey);
}
.FormTabs .tab-list-item:hover,
.FormTabs .tab-list-item:focus {
  color: var(--color-black);
}
.FormTabs .tab-list-item.active {
  color: var(--color-black);
  border-radius: 3px 3px 0 0;
  border-bottom: 2px solid var(--color-black);
}

/* 
---------------------------------------------------------------
Icons
--------------------------------------------------------------- 
*/
.icon {
  width: 1.2em;
  height: 1.2em;
  content: "";
  margin: 0 auto 0 auto;
  display: block;
}

/* Other */
.icon-kayenta {
  background: url('./assets/img/logo.svg');
  background-repeat: no-repeat;
  background-position: center center;
  width: 100px;
  height: 30px;
}

.icon-user-photo {
  background: url('./assets/img/icons/user avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.icon-group-photo {
  background: url('./assets/img/icons/user group avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.icon-not-found {
  width: 120px !important;
  height: 120px !important;
}

.icon-not-found path {
  fill: url(#icon-not-found-gradient) !important;
}


@keyframes spin { 
  100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } 
}

/* 
---------------------------------------------------------------
CustomButton
--------------------------------------------------------------- 
*/

.CustomButton {
  min-width:  35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CustomButton.white {
  background: #fff;
  border: 1px solid var(--color-medium-grey);
  border-radius:  3px;
}

.CustomButton.white:hover,
.CustomButton.white:active {
  border: 1px solid var(--color-black);
}

.CustomButton.white:active {
  transform:  scale(0.95);
}

.CustomButton .icon {
  margin-right: 10px;
}

.button {
  cursor: pointer;
  font-family: 'Rubik-Regular';
  text-transform: uppercase;
  border-radius: 5px;
  border:  none;
  height: 35px;
  background: none;
}
.button:nth-child(n+2) {
  margin: 0 0 0 5px;

}
.button span {
  display: flex;
  border-radius: 5px;
  padding: 0px 13px 0px 13px;
  transition: background-color 0.1s ease-out; 
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 35px;
  min-width: 50px;
  white-space: nowrap;
  background: #22202011;
}

.large-button {
  font-size: 1.5em;
  font-family: 'Rubik-Medium';
  text-transform: uppercase;
  border-radius: 100px;
  width: 100%;
  margin: 0 !important;
  height: auto;
  max-width: 400px;
  background: #E4C3FF; /* fallback for browsers that cannot do gradient backgrounds */
  background: linear-gradient(170deg, var(--color-pink), #E4C3FF 80%);
}
.large-button span {
  display: block;
  border-radius: 100px;
  border: none;
  background: rgba(35,31,32,0);
  color: #fff;
  height: auto;
  padding: 10px 25px;
  transition: background 0.1s ease-out, transform 0.1s ease-in;
}

.main-button {
  background: #E4C3FF; /* fallback for browsers that cannot do gradient backgrounds */
  background: linear-gradient(150deg, var(--color-pink), #E4C3FF 80%);
  color: #fff;
  border:  none; 
}

.main-button span {
  background: rgba(35,31,32,0);
  border: none;
}

.secondary-button {
  display: inline-flex !important;
}
.secondary-button span {
  background: var(--color-light-grey);
  display: inline-flex !important;
  border: none;
}

.button:disabled,
.button.disabled,
.button.loading {
  cursor: initial;
}

.button:disabled span,
.button:disabled:hover span,
.button.disabled span,
.button.disabled:hover span {
  color: var(--color-dark-grey);
  background: var(--color-light-grey);
  transform: none !important;
  cursor: default;
}

.main-button:disabled span,
.large-button:disabled span,
.main-button:disabled:hover span,
.large-button:disabled:hover span,
.main-button.disabled span,
.large-button.disabled span,
.main-button.disabled:hover span,
.large-button.disabled:hover span {
  background: var(--color-light-grey);
}


.button.loading span,
.button.loading:hover span,
.button.loading {
  color: var(--color-dark-grey) !important;
  background-color: var(--color-light-grey);
  animation: button-loading-animation 1.2s ease-in-out infinite;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      90deg,
      rgba(255,255,255,0),
      rgba(255,255,255,1),
      rgba(255,255,255,0)
    ) !important;
}

@keyframes button-loading-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.main-button:hover span,
.button:hover span {
  background: rgba(35,31,32,1);
  color: #fff;
}

.large-button:hover span {
  background: rgba(35,31,32,1);
  
}

.button:active {
  transform: scale(0.95) !important;
}

.button.loading:active,
.button:disabled:active,
.button.disabled:active {
  transform: none !important;
}


/* 
---------------------------------------------------------------
CustomCheckbox 
--------------------------------------------------------------- 
*/
/* Customize the label (the container) */
.CustomCheckbox {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: max-content;
}

/* Hide the browser's default checkbox */
.CustomCheckbox input {
  position: absolute;
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
}

.CustomCheckbox span {
  vertical-align: middle;
  display: inline-flex;
}

.CustomCheckbox .label {
  margin: 0 0 0 10px;
}

.CustomCheckbox.label-position-left {
  display: flex;
  flex-direction: row-reverse;
}
.CustomCheckbox.label-position-left .label {
  margin:  0 10px 0 0;
  min-width: 100px;
}


.CustomCheckbox.disabled .label {
  color: var(--color-dark-grey);
}

/* Create a custom checkbox */
.CustomCheckbox .checkmark {
  height: 17px;
  width: 17px;
  border-radius: 4px;
  border: 1px solid var(--color-medium-grey);
  cursor: pointer;
  background: var(--color-very-light-grey);
  position: relative;
}
.CustomCheckbox.disabled,
.CustomCheckbox.disabled .checkmark {
  cursor: default;
}
/* On mouse-over */
.CustomCheckbox:hover input ~ .checkmark {
  border-color: var(--color-black);
}

.CustomCheckbox.disabled:hover input ~ .checkmark {
  border-color: var(--color-medium-grey);
}

/* When the checkbox is checked */
.CustomCheckbox input:checked ~ .checkmark {
  background: var(--color-black);
  height: 17px;
  width: 17px;
  border: 1px solid transparent;
}

.CustomCheckbox input:disabled:checked ~ .checkmark,
.CustomCheckbox.dim-selected .checkmark {
  background: var(--color-medium-grey);
}
.CustomCheckbox:active .checkmark {
transform: scale(0.8) !important;
}
.CustomCheckbox.disabled:active .checkmark {
transform: none !important;
}

/* Style the checkmark/indicator */
.CustomCheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
} 

.CustomCheckbox .checkmark.null:after {
  content: "";
  position: absolute;
  display: block;
  left: 3px;
  top: 3px;
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 2px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
} 

/* Show the checkmark when checked */
.CustomCheckbox input:checked ~ .checkmark:after,
.CustomCheckbox.dim-selected .checkmark:after {
  display: block;
}


/* 
---------------------------------------------------------------
CustomSelect
--------------------------------------------------------------- 
*/

.CustomSelect {
  width: 100%;
}

.CustomSelect label {
  display:  flex;
  margin-bottom: 5px;
  flex-direction: row;
  font-size: 0.75em;
  font-family: Rubik-Medium;
}

.CustomSelect .select-container {
  position: relative;
  background-color: var(--color-very-light-grey);
  border-radius: 3px;
  min-height: 35px;
  width: 100%;
  transition: width 0.1s ease-out;
}

.CustomSelect .select-input-container,
.CustomSelect .select-input {
  margin: 0;
  height: 35px;
}

.CustomSelect .select-input-container {
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
.CustomSelect .select-input {
  margin: 0;
  background: none !important; 
  border: solid 1px var(--color-light-grey);
  padding: 10px 15px 10px 15px;
  clear: both;
  border-radius: 3px;
  width: 100%;
}

.CustomSelect .select-input:hover,
.CustomSelect .select-input:focus {
  border: solid 1px var(--color-black);
}

.CustomSelect .select-input:disabled {
  color: var(--color-dark-grey);
}
.CustomSelect .select-input:disabled:hover {
  border: solid 1px var(--color-light-grey) !important;
}

.CustomSelect .input-icon {
  position: absolute;
  top: 0;
  left: 0px;
  width: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.CustomSelect .select-value-container.disabled .select-value-label {
  color: var(--color-dark-grey);
}

.CustomSelect.show .select-container .select-input {
  border: 1px solid var(--color-black);
  border-radius: 3px 3px 0 0;  
 
}
.CustomSelect.show.up .select-container .select-input {
  border-radius: 0 0 3px 3px;   
}


.CustomSelect .select-value-container { 
  position: absolute;
  margin: 0 10px 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  width:  calc(100% - 25px);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.1s ease-out;
}
.CustomSelect .select-value-container.placeholder {
  color: var(--color-dark-grey);
}
.CustomSelect .select-value { 
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CustomSelect .select-value-label { 
  margin: 0;
}

.CustomSelect .select-input-container:hover .select-input {
  border: 1px solid var(--color-black);
}


.CustomSelect .select-button-container {
  position: absolute; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  right: 0.3vw;
  background-color: var(--color-very-light-grey);
  top: 1px; /*calc(50% - (1.2em + 15px)/2);*/
}
.CustomSelect .select-button {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  width: 30px;
  height: 33px;
  /*background-color: var(--color-very-light-grey);*/
  border-radius: 3px;
}

.CustomSelect .select-input-container:hover .button-toggle-menu,
.CustomSelect.show .select-container .button-toggle-menu {
  /*background-color: #fff;*/
}

.CustomSelect .button-clear-selection:hover .button-clear-selection-icon::after,
.CustomSelect .button-clear-selection:hover .button-clear-selection-icon::before {
  background: var(--color-black);
}

.CustomSelect .button-toggle-menu::after {
  display: block;
  content: '';
  width: 0vw;
  height: 0vw;
  border-top: 7px solid var(--color-black);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.CustomSelect .button-clear-selection {
  cursor: pointer;
}

.CustomSelect .button-clear-selection .button-clear-selection-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.CustomSelect .button-clear-selection .button-clear-selection-icon::before {
  width: 12px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0vw;
  display: block;
}
.CustomSelect .button-clear-selection .button-clear-selection-icon::after {
  width: 2px;
  height: 12px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.CustomSelect .select-options {
  display: none;
  position: absolute;
  z-index: 200;
  border-top: 1px solid #E0D4D0;
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-black);
  border-radius: 0 0 3px 3px;
  margin-top: -1px;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-very-light-grey);

}
.CustomSelect.up .select-options {
  margin-bottom: -1px;
  border-top: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid #E0D4D0;
  border-radius: 3px 3px 0 0;
}
.CustomSelect.show .select-options {
  display: block;
}
.CustomSelect .select-option,
.CustomSelect .select-options-empty {
  margin: 0;
  padding: 8px 15px 8px 15px;
  min-height: 35px;
  cursor: default;
  display: flex;
  flex-direction: row;
}
.CustomSelect .select-option.narrow-padding {
  padding: 7px 15px 7px 15px;
}

.CustomSelect .select-option.selected {
  background: var(--color-light-grey);
}

.CustomSelect .select-option-checkbox {
  margin: 0 10px 0 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CustomSelect .select-option-checkbox label {
  margin: 0
}

.CustomSelect .select-option-icon {
  margin: 0 15px 0 0;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomSelect .select-option-label {
  display: flex;
  flex-direction: column;
}
.CustomSelect .select-option-secondary-label {
  color: var(--color-dark-grey);
  font-size: 0.75em;
  text-align: left;
}

.CustomSelect .select-option-label,
.CustomSelect .icon {
  margin: 0;
}
.CustomSelect .select-option:hover,
.CustomSelect .select-option:focus,
.CustomSelect .select-option.active {
  background: var(--color-light-purple);
}

.CustomSelect .select-option:hover .CustomCheckbox input ~ .checkmark,
.CustomSelect .select-option:focus .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-black);
}


.CustomSelect .select-options-empty {
  font-style: italic;
  color: var(--color-dark-grey);
}




.CustomSelect.with-icon .select-input {
  padding-left: 32px;

}
.CustomSelect.with-icon .select-value-container {
  margin: 0 10px 0 35px;
  width: calc(100% - 10px - 35px);
}

.CustomSelect.invalid .select-input {
  border: 1px solid var(--color-intense-red);
}



.CustomSelect.collapsed .select-container {
  width: 35px !important;
  padding-right: 0;
}
.CustomSelect.collapsed .select-value-container {
  width: 0;
}

.CustomSelect.collapsed .input-icon {
  cursor: pointer;
}
.CustomSelect.collapsed .select-input {
  border: solid 1px var(--color-medium-grey);
  padding-right:  0px;
}

.CustomSelect.collapsed .select-button-container {
  display:  none;
}

.CustomSelect.icon-as-button .select-button-container {
  background: #fff;
}


.graph-controls .CustomSelect {
 
}

.graph-controls .CustomSelect .select-container {
  background: #fff !important; 
  width:  300px; 
  position:  absolute;
  right:  0;
  top:  0;
}

.graph-controls .CustomSelect .select-option,
.graph-controls .CustomSelect .select-option.active,
.graph-controls .CustomSelect .select-options-empty {
  background:  #fff;
}
.graph-controls .CustomSelect .select-option:hover {
  background: var(--color-light-purple);
}


.graph-controls .CustomSelect .select-container {
  /*border: 0px solid var(--color-medium-grey);*/
}
.graph-controls .CustomSelect:hover .select-container {
  /*border: 0px solid var(--color-black);*/
}



/* 
---------------------------------------------------------------
CustomSelectButtons
--------------------------------------------------------------- 
*/

.CustomSelectButtons label {
  display:  flex;
  flex-direction: row;
}

.CustomSelectButtons .select-container {
  border: 1px solid var(--color-light-grey);
  border-radius: 3px;
  display: inline-flex;
  height: 35px;
}

.CustomSelectButtons .select-options {
  display: inline-flex;
  background-color: var(--color-very-light-grey);
  border-radius: 3px;
  flex-direction: row;

}
.CustomSelectButtons .select-option:nth-child(n+2) {
  margin-left: 2px;
}
.CustomSelectButtons .select-option {
  margin: 0;
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.CustomSelectButtons .select-option-icon {
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomSelectButtons .select-option-label,
.CustomSelectButtons .icon {
  margin: 0;
}
.CustomSelectButtons .select-option:hover,
.CustomSelectButtons .select-option:focus {
  background: var(--color-light-purple);
}

.CustomSelectButtons .select-option:active {
  transform: scale(0.95);
}
.CustomSelectButtons .select-option.active {
  background: var(--color-black);
  color: #fff;
}

.CustomSelectButtons .select-container.disabled .select-option,
.CustomSelectButtons .select-option.disabled {
  color: var(--color-dark-grey);
  cursor: default;
}

.CustomSelectButtons  .select-container.disabled .select-option:hover,
.CustomSelectButtons .select-container.disabled .select-option:focus,
.CustomSelectButtons .select-option.disabled:hover,
.CustomSelectButtons .select-option.disabled:focus {
  background: none;
}

.CustomSelectButtons .select-container.disabled .select-option:active,
.CustomSelectButtons .select-option.disabled:active {
  transform: none;
}
.CustomSelectButtons .select-container.disabled .select-option.active {
  background: var(--color-medium-grey);
  color: #fff;
}

/* 
---------------------------------------------------------------
CustomInput
--------------------------------------------------------------- 
*/
.CustomInput {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.CustomInput .input-container {
  position: relative;
}
.CustomInput input {
  max-width: 400px;
  border: solid 1px var(--color-light-grey);
  padding: 0px 15px 0px 15px;
  height: 35px;
  border-radius: 3px;
  width: 100%;
  transition: width 0.1s ease-out;
}

.CustomInput input:focus,
.CustomInput input:hover  {
  border: solid 1px var(--color-black);
}

.CustomInput input:disabled {
  color: var(--color-dark-grey);
}
.CustomInput input:disabled:hover {
  border: solid 1px var(--color-light-grey);
}


.CustomInput.large input {
  margin: 0 0 2vh 0;
  padding: 10px;
  border: none;
  border-radius: 0;
}

.CustomInput label {
  margin-bottom: 10px;
  display:  flex;
}

.CustomInput.label-position-left {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CustomInput.label-position-left label {
  margin:  0 10px 0 0;
  min-width: 100px;
}
.CustomInput.label-position-left .input-container {
  display: flex;
  flex-grow: 1;
}

label .icon-description {
  width: 13px;
  height:  13px;
  font-size: 0.7em;
  font-family: Rubik-Bold;
  color: var(--color-dark-grey);
  display:  flex;
  justify-content: center;
  align-items: center;
  border:  1px solid var(--color-dark-grey);
  border-radius: 10px;
  margin:  0 0 0 10px;
}

.CustomInput.large input {
  font-size: 1.5em;
  height: initial;
  border-bottom: 1px solid var(--color-medium-grey);
  background: none;
}

.CustomInput.label-as-placeholder {
  display: flex;
  align-items: center;

}

.CustomInput.label-as-placeholder input {
  padding-top: 30px !important;
  font-family: "Courier";
}

.CustomInput.label-as-placeholder label {
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  font-size: 1.5em;
  color: var(--color-dark-grey);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 25px);
  transform-origin: top center;
  transition: all 0.1s ease-out;
}

.CustomInput.label-as-placeholder:focus-within label,
.CustomInput.label-as-placeholder .active {
  transform: translate(0, 0vw);
  font-size:  0.8em;
}

.CustomInput.large input {
  text-align: center;
}

.CustomInput.large input:focus,
.CustomInput.large input:hover {
  border-bottom: 1px solid var(--color-black);
}

.CustomInput.large input:disabled  {
  color: var(--color-dark-grey);
  background: none;
}

.CustomInput.large input:disabled:hover {
   border: none;
   border-bottom: 1px solid var(--color-medium-grey);
}

.CustomInput.has-error input  {
  border-bottom: 1px solid var(--color-intense-red) !important;
}

.CustomInput .button-clear-input {
  position: absolute; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  right: 5px;
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.CustomInput .button-clear-input:hover .button-clear-input-icon::after,
.CustomInput .button-clear-input:hover .button-clear-input-icon::before {
  background: var(--color-black);
}

.CustomInput .button-clear-input .button-clear-input-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.CustomInput .button-clear-input .button-clear-input-icon::before {
  width: 12px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.CustomInput .button-clear-input .button-clear-input-icon::after {
  width: 2px;
  height: 12px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.CustomInput .input-icon {
  position: absolute;
  top: 0;
  left: 0px;
  width: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomInput.with-icon input {
  padding-left: 35px;
}

.CustomInput.collapsed input {
  width: 35px;
  padding-right: 0;
  padding-left: 32px;
}
.CustomInput.collapsed input {
  width: 35px;
  padding-right: 0;
  padding-left: 32px;
}

.CustomInput.collapsed .input-icon {
  cursor: pointer;
}


/* 
---------------------------------------------------------------
CustomTextarea
--------------------------------------------------------------- 
*/
.CustomTextarea .public-DraftEditor-content {
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 8px 15px 0px 15px;
  clear: both;
  /*border-radius: 3px;*/
  width: 100%;
  min-height: calc(10vh - 35px);
  max-height: calc(30vh - 35px);
  overflow-y: auto;
  line-height: 1.3em;
  border-radius: 3px 3px 0 0;
  border-bottom: none !important;
}

.CustomTextarea .public-DraftEditor-content,
.CustomTextareaReadOnly .public-DraftEditor-content {
  line-height: 1.3em;
  overflow-wrap: normal !important;
}

.CustomTextarea .public-DraftStyleDefault-block,
.CustomTextareaReadOnly .public-DraftStyleDefault-block {
  margin-bottom: 10px;
}

.CustomTextarea .public-DraftStyleDefault-block,
.CustomTextareaReadOnly .public-DraftStyleDefault-block {
  margin-bottom: 10px;
}

.CustomTextarea:hover .public-DraftEditor-content,
.CustomTextarea:hover .format-menu,
.CustomTextarea.has-focus .format-menu  {
  border-color: var(--color-black);
}
.CustomTextarea.disabled .public-DraftEditor-content {
  color: var(--color-dark-grey);
}
.CustomTextarea.disabled .public-DraftEditor-content li {
  color: var(--color-dark-grey);
}
.CustomTextarea.disabled .public-DraftEditor-content:hover {
  border: solid 1px var(--color-light-grey);
}
.CustomTextarea.disabled .textarea-reference:hover {
  color: var(--color-dark-grey);
  cursor: text;
}

.CustomTextarea.has-focus .format-menu-content {
  display: flex;
}

.CustomTextarea .format-menu-button:nth-child(n+1) {
  margin-right: 5px;
}

.CustomTextarea .public-DraftEditor-content:focus {
  border: solid 1px var(--color-black);
}
.CustomTextarea .public-DraftEditor-content:disabled {
  color: var(--color-dark-grey);
}
.CustomTextarea .public-DraftEditor-content:disabled:hover {
  border: solid 1px var(--color-light-grey);
}
.CustomTextarea.disabled:hover .public-DraftEditor-content,
.CustomTextarea.disabled:hover .format-menu {
  border-color: var(--color-light-grey);
}

.CustomTextarea .format-menu {
  height: 35px;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0 10px 0 10px;
  background: var(--color-very-light-grey);
  border-radius: 0 0 3px 3px;
  border: solid 1px var(--color-light-grey);
  border-top: none;
}

.CustomTextarea .format-menu-content {
  height: 35px;
  display: none;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--color-medium-grey)88;
  width: 100%;
}

.CustomTextarea .format-menu-button-group {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.CustomTextarea .format-menu-button {
  border-radius: 3px; 
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.CustomTextarea .format-menu-button:hover,
.CustomTextarea .format-menu-button.active {
  background: var(--color-black);
  color: #fff;
}

.CustomTextarea .format-menu-button:hover .icon-editor path,
.CustomTextarea .format-menu-button.active .icon-editor path {
  fill: #fff !important;
}

.CustomTextarea .textarea-reference,
.CustomTextareaReadOnly .textarea-reference {
  font-family: "Rubik-Regular";
  border-bottom: 1px dotted var(--color-medium-grey) !important;
  cursor: pointer;
}
.CustomTextarea .textarea-reference:hover,
.CustomTextareaReadOnly .textarea-reference:hover {
  color: #E4C3FF;
}

.CustomTextarea .format-menu-button .bold {
  font-family: 'Rubik-Medium';
}

.CustomTextarea .format-menu-button .italic {
  font-family: "Courier New", Courier, monospace;
  font-style: italic;
}

.CustomTextarea .format-menu-button .underline {
  text-decoration: underline;
}

.CustomTextarea ul,
.CustomTextarea ol,
.CustomTextareaReadOnly ul,
.CustomTextareaReadOnly ol {
  margin-left: 15px;
  margin-bottom: 10px !important;
}
.CustomTextarea li,
.CustomTextareaReadOnly li {
  margin-bottom: 5px !important;
}

.CustomTextarea li div,
.CustomTextareaReadOnly li div {
  margin-bottom: 0px !important;
}


.CustomTextareaSuggestions {
  background: #fff;
  border: 0px solid var(--color-medium-grey);
  display: inline-block;
  position: absolute;
  z-index: 1000;
  border-radius: 3px;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
  white-space: nowrap;
}

.CustomTextareaSuggestions .content {
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.CustomTextareaSuggestions .option {
  cursor: pointer;
  /*display: flex;*/
  align-items: center;
}

.CustomTextareaSuggestions table {
  border-collapse: collapse;
  
}

.CustomTextareaSuggestions .no-results {
  padding: 4px 30px 4px 10px;
  font-size: 0.8em;
  color: var(--color-dark-grey);
}

.CustomTextareaSuggestions .option:hover, 
.CustomTextareaSuggestions .option.selected {
  background: var(--color-light-purple);
}

.CustomTextareaSuggestions .object-type {
  padding: 2px 10px 2px 10px;
  color: var(--color-dark-grey);
  font-size: 0.8em;
  font-family: "Rubik-Regular";
  text-align: left;

  
}

.CustomTextareaSuggestions .object-name {
  padding: 2px 10px 2px 10px;
  width:  100%;

}

.CustomTextareaSuggestions .object-name .bold {
  font-family: "Rubik-Regular";

}

.CustomTextareaSuggestions .footer {
  position: static;
  bottom: 0;
  color: var(--color-dark-grey);
  font-size: 0.8em;
  padding: 4px 10px;
}

/* 
---------------------------------------------------------------
RelationshipGraph
--------------------------------------------------------------- 
*/
.RelationshipGraph {
  align-items: center;
  justify-content: center;
  display:  flex;  
  flex-grow: 1;
}

.graph-canvas {
  width:  100%;
  height:  100%;
}

.graph-controls {
  position:  absolute;
  top:  0;
  right:  0;
  z-index:  1;
  width:  35px;
}

.graph-controls .graph-control {
  margin-bottom: 5px;

}

.graph-controls .filter-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:  relative;
  top:  0;
  height:  35px;
}

.graph-legend {
  position:  absolute;
  bottom: 0;
  right: 0;
  font-size:  0.8em;
  color: var(--color-dark-grey);
  display:  flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.graph-legend-text {
  background:  var(--color-very-light-grey);
  height: 20px;
  display:  flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 5px;
}
.graph-legend-icon {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.graph-legend-circle-min {
  position:  absolute;
  bottom:  20px;
  right:  0;
  width: 42px;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.graph-legend-circle-max {
  position:  absolute;
  bottom:  20px;
  right: 0;
  width: 42px;
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:  var(--color-very-light-grey);
  overflow: hidden;
}

.graph-legend-value {
  height:  16px;
  text-align: center;
}
.graph-legend-min {
  box-sizing: initial;
  content: "";
  border:  1px solid var(--color-dark-grey);
  border-radius: 100%;
  width:  5px;
  height: 5px;
}
.graph-legend-max {
  box-sizing: initial;
  content: "";
  border:  1px solid var(--color-dark-grey);
  border-radius: 100%;
  width:  40px;
  height: 40px;
}


.RelationshipGraph .loading-graph {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: var(--color-dark-grey);
}

.RelationshipGraph .loading-graph-spinner {
  width:  160px;
  height:  135px;
  content: "";
  display: block;
}

.RelationshipGraph .loading-graph-text {
  margin-top:  20px;
}





.selected-items-popup {
  position:  absolute;
  top:  0;
  left:  0;
  padding:  15px 20px 5px 20px;
  background:  #fff;
  z-index:  2;
  border:  1px solid var(--color-light-grey);
  border-radius: 5px;
  max-height:  100%;
  display:  flex;
  flex-direction: column;
  overflow:  hidden;
  /*box-shadow: 1px 1px 1px 1px rgba(35,31,32,0.2);*/
}

.selected-items-popup-header .icon {
  margin-right:  10px;
}
.selected-items-popup-title {
  font-size:  0.9em;
  color:  var(--color-dark-grey);
  margin:  0 25px 10px 0;
}

.selected-items-popup.one-item .selected-items-popup-title {
  display:  none;
  flex-direction: row;
}

.selected-items-popup-content {
  overflow-x:  hidden;
  overflow-y:  auto;
  display:  flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.selected-items-popup-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  justify-content: flex-start;
}

.selected-items-popup.one-item .selected-items-popup-content {

}
.selected-items-popup.one-item .selected-items-popup-section {
  overflow-x:  hidden;
  overflow-y:  auto;
}


.selected-items-popup-section:nth-last-child(n+2) {
  margin:  0 0 30px 0;
}


.selected-items-popup-section-title {
  display:  flex;
  flex-direction:  row;
  align-items: flex-start;
  font-family: "Rubik-Medium";
}
.selected-items-popup-section-title .link {
  font-family: "Rubik-Medium";
}
.selected-items-popup-section-title .link:hover {
  color:  #E4C3FF;
}
.selected-items-popup-section-title .icon {
  margin:  0 10px 0 0;
}




.selected-items-popup-section-description {
  margin: 10px 0 0 0;
  font-size:  0.9em;
}

.selected-items-popup-section-description.empty {
  margin: 0 0 0 0;
}

.selected-items-popup-section-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  overflow: hidden;
  justify-content: flex-start;
}
.selected-items-popup-section-footer {
  margin:  5px 0 0 0;  
}
.toggle-all-fields {
  font-size:  0.8em;
  cursor: pointer; 
  display:  inline-block;
  margin:  0 0 0 35px;
  color: var(--color-dark-grey);
}
.toggle-all-fields:hover {
  color:  var(--color-black); 
}
/*
.CustomTabs .tab-content {
  margin: 10px auto 0 auto;
  display:  flex;
  flex-direction: column;
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
}

.CustomTabs .tab-content-wrapper {
  margin: 0px auto 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  relative;
  margin-bottom:  10px;
}*/



.selected-items-popup .DataTable {
  position: initial;
}

.selected-items-popup .DataTable .row {
  font-size:  0.9em;
}
.selected-items-popup .DataTable .row:hover {
  background-color:  var(--color-light-purple);
}

.selected-items-popup .DataTable td {
  padding:  5px;
}
.selected-items-popup .DataTable th {
  padding:  0px 5px 5px 5px;
  padding-bottom:  5px;
  background-color:  #fff;
}
.selected-items-popup .DataTable .type-icon {
  width:  30px;
}
.selected-items-popup .empty-text {
  margin: 10px;
  font-size:  0.9em;
}

.toggle-popup {
  position:  absolute;
  top:  10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
}
.toggle-popup::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 10px;
  display: block;
}
.toggle-popup::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
  transform: rotate(90deg);
}
.toggle-popup:hover {
  background: var(--color-black);
}
.toggle-popup:hover::before, 
.toggle-popup:hover::after {
  background: #fff;
}

.selected-items-popup.hide {
transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
}

.selected-items-popup.hide .selected-items-popup-content
 {
  display:  none;
}
.selected-items-popup.hide .selected-items-popup-title {
  color:  var(--color-black);
  font-family: "Rubik-Medium";
}

.selected-items-popup.hide:hover {
  border:  1px solid var(--color-black);
  cursor: pointer;
  background:  var(--color-black);
}
.selected-items-popup.hide:hover .selected-items-popup-title {
  color:  #fff;
}


.selected-items-popup.hide.one-item .selected-items-popup-title {
  display:  flex;
  font-size: 1em;
}
.selected-items-popup.hide .toggle-popup {
  top:  12px;
  transform: rotate(45deg);
}
.selected-items-popup.hide .toggle-popup::after {
  top:  5px;
  left:  4px;
  width:  13px;
}
.selected-items-popup.hide .toggle-popup::before {
  top:  13px;
  left:  10px;
  width:  13px;
}

.selected-items-popup.hide:hover .toggle-popup {
  background: transparent;
}
.selected-items-popup.hide:hover .toggle-popup::after {
  background: #fff;
}
.selected-items-popup.hide:hover .toggle-popup::before {
  background: #fff;
}


/* 
---------------------------------------------------------------
CustomTooltip
--------------------------------------------------------------- 
*/

.CustomTooltip {
  position:  absolute;
  display:  flex;
  /*visibility: hidden;*/
  flex-direction: column;
  background:  #fff;
  /*border:  1px solid var(--color-dark-grey)66;*/
  border-radius: 3px;
  box-shadow: 1px 1px 2px 1px rgba(35,31,32,0.2);
  padding:  8px;
  z-index:  100;
  max-width: 500px;
  font-size:  0.9em;

}
/*.CustomTooltip.show {
  display:  flex;
  visibility: visible;
}*/

.CustomTooltip .graph-tooltip {
  display:  flex;
  flex-direction: row;
}

.CustomTooltip .graph-tooltip-edge-direction {
  padding:  0 7px 0 14px;
  display: flex;
}

.CustomTooltip .first-row {
 font-family: "Rubik-Regular";
 display:  flex;
 flex-direction:  row;
 align-items: center;
}
.CustomTooltip .second-row { 
  margin: 3px 0 0 19px;
}

.CustomTooltip .tooltip-subtitle {
  margin: 5px 0 0 0;
  color:  var(--color-dark-grey);
  font-size:  0.9em;
  display:  flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
}
.CustomTooltip .inverted-margin {
  margin: 0 0 5px 0 !important;
}
.CustomTooltip .tooltip-title { 
  font-family: "Rubik-Regular";
  font-size:  1.0em;
  display:  flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
}
.CustomTooltip .tooltip-title.thin { 
  font-family: "Rubik-Light";
}

.CustomTooltip .icon {
  margin:  0 7px 0 0;
}

.CustomTooltip .tooltip-pretitle { 
  text-transform: uppercase;
  margin:  0 0 5px 0;
  font-size:  0.6em;
  font-family: "Rubik-Regular";
}

.CustomTooltip .tooltip-body { 
  display:  flex;
  margin: 5px 0 0 0;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
}

.CustomTooltip.no-title .tooltip-body { 
  margin: 0 0 0 0;
}
.CustomTooltip.no-title { 
  padding: 4px 8px;
}

/* 
---------------------------------------------------------------
CustomSlider
--------------------------------------------------------------- 
*/
.CustomSlider {
  display:  flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--color-very-light-grey);
  border-radius:  3px;
}

.CustomSlider.vertical .input-container {
  width:  150px;
  height:  20px;
  margin: 75px 0;
  transform:  rotate(-90deg);
  order:  2;
}

.CustomSlider.vertical .input-container::after {
  width:  2px;
  height:  9px;
  background:  var(--color-medium-grey);
  content:  '';
  position:  absolute;
  top:  6px;
  left:  75px;
  z-index: 1;
}

/* Remove base styles */
.CustomSlider input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  z-index: 2;
  position:  absolute;
  
}

.CustomSlider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.CustomSlider input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.CustomSlider input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Apply styles to slider */
.CustomSlider input[type=range]::-webkit-slider-runnable-track {
  /*width: 100%;*/
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
  box-shadow: none;
  margin-top: 7px
}


.CustomSlider input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--color-medium-grey);
}

.CustomSlider input[type=range]::-moz-range-track {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}

.CustomSlider input[type=range]::-ms-track {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]::-ms-fill-lower {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]:focus::-ms-fill-lower {
  background: var(--color-medium-grey);
}
.CustomSlider input[type=range]::-ms-fill-upper {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]:focus::-ms-fill-upper {
  background: var(--color-medium-grey);
}

/* Apply styles to thumb */
.CustomSlider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.CustomSlider input[type=range]::-moz-range-thumb {
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
.CustomSlider input[type=range]::-ms-thumb {
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
}

.CustomSlider input[type=range]::-moz-range-thumb:hover,
.CustomSlider input[type=range]::-moz-range-thumb:active {
  border: 1px solid var(--color-black);
}

.CustomSlider input[type=range]::-webkit-slider-thumb:hover,
.CustomSlider input[type=range]::-webkit-slider-thumb:active {
  border: 1px solid var(--color-black);
}

.CustomSlider input[type=range]::-ms-thumb:hover,
.CustomSlider input[type=range]::-ms-thumb:active {
  border: 1px solid var(--color-black);
}



.CustomSlider.horizontal .max-label {
  order:  3;
  padding-left:  5px;
  
}
.CustomSlider.horizontal .min-label {
  order:  1;
  padding-right:  5px;
  
}
.CustomSlider .max-label {
  order:  1;
  font-family:  "Rubik-Regular";
}
.CustomSlider .min-label {
  order:  3;
  font-family:  "Rubik-Regular";
}
.CustomSlider.vertical {
  flex-direction: column;
  
}

/* 
---------------------------------------------------------------
ADSearchResult
--------------------------------------------------------------- 
*/

.side-dialog-search-input {
  position: relative;
  margin-bottom: 20px;
}
.side-dialog-search-loading-indicator {
  position: absolute;
  bottom: -24px;
  margin-left: 10px;
  color: var(--color-dark-grey);
  font-size: 0.9em;
}
.side-dialog-search-result-count {
  position: absolute;
  bottom: -24px;
  right: 0;
  margin-right: 10px;
  color: var(--color-dark-grey);
  font-size: 0.9em;
}

.side-dialog-search-result {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-radius: 3px;
  position: relative;
}

.side-dialog-search-result:active {
  transform: scale(0.98);
}

.side-dialog-search-result.selected {
  color: var(--color-dark-grey);
}

.side-dialog-search-result.disabled:active {
  transform:  none;
} 
.side-dialog-search-result.selected:active {
  transform: none;
}

.side-dialog-search-result-photo {
  display:  flex;
  justify-content: center;
  margin-right: 10px;
}

.side-dialog-search-result-icon {
  display:  flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  margin-right: 10px;
}
.side-dialog-search-result-photo .photo-img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.side-dialog-search-result-photo .photo-img.placeholder {
  border-radius: 0%;
}
.side-dialog-search-result.selected .ad-search-result-photo {
  opacity: 0.2;
}
.side-dialog-search-result-body {
  display: flex;
  flex-direction: column;
}
.side-dialog-search-result-title {
  width: 100%;
  display:  flex;
  flex-direction: row;
  justify-content: flex-start;
}
.side-dialog-search-result-subtitle {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  width: 100%;
}
.side-dialog-search-result-selected {
  position: absolute;
  right: 10px;
  top:  0px;
  height:  100%;
  display:  flex;
  align-items: center;
  font-size: 0.9em;
  font-style: italic;
  background: #fff;
  padding: 0 0 0 10px;
}

.side-dialog-search-result:hover {
  background: var(--color-light-purple);
  cursor: pointer;
}
.side-dialog-search-result.disabled:hover {
  background: initial;
  cursor: default;
} 
.side-dialog-search-result.selected:hover {
  background: initial;
  cursor: default;
}

/* 
---------------------------------------------------------------
Photo
--------------------------------------------------------------- 
*/
.Photo .photo-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.Photo.small,
.Photo.small .photo-img,
.Photo.small .photo-loader {
  width: 32px;
  height: 32px;
}

.Photo.small.placeholder,
.Photo.small.placeholder .photo-img,
.Photo.small.placeholder .photo-loader {
  width: 28px;
  height: 28px;
}

.Photo.medium,
.Photo.medium .photo-img,
.Photo.medium .photo-loader {
  width: 80px;
  height: 80px;
}

.Photo.large,
.Photo.large .photo-img,
.Photo.large .photo-loader {
  width: 120px;
  height: 120px;
}

.Photo.placeholder .photo-img {
  border-radius: 0%;
}

/* 
---------------------------------------------------------------
UserLink
--------------------------------------------------------------- 
*/
.UserLink {
  display:  inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}
.UserLink .link {
  display:  flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.UserLink .Photo {
  margin: 0 10px 0 0;
}


/* 
---------------------------------------------------------------
Skeleton
--------------------------------------------------------------- 
*/
.react-loading-skeleton {
  border-radius: 100px !important;
  background-color: rgba(35,31,32,0.05) !important;
}

.react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    rgba(255,255,255,0),
    rgba(255,255,255,1),
    rgba(255,255,255,0)
  ) !important;
}


@media (max-aspect-ratio: 1/1) {

  .LoadApp .fullscreen-content {
    padding-top: 25vh;
  }

  .Login .fullscreen-content {
    padding-top: 5vh;
  }

  .fullscreen-logo {
    margin-bottom: 0;
  }

  .breadcrumb-item-container .icon-kayenta {
    margin-left: 0;
  }

  .breadcrumb-item-container.apps-link {
    padding: 0 ;
  }
  .breadcrumb-item-container.apps-link .link {
    margin: 0;
  }

  .breadcrumb-item.first-in-app {
    margin: 0;
    padding: 0;
  }
  .breadcrumb-item.first-in-app span {
    margin-left: 0;
  }


/*
.main-content .columns {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.MainColumn {
  width: 30vw;
  background: #fff;
  border-right: 1px solid var(--color-light-grey);
  min-width: 400px;
  max-width: 500px;
  flex-grow: 1;
  transition: width 0.2s ease-out, max-width 0.2s ease-out, min-width 0.2s ease-out, padding 0.2s ease-out;
  position: relative;
}
.main-content .columns .column {
  padding: 10px 20px 0px 20px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
.MainColumn.column {
  padding: 20px 20px 0px 30px !important;
}
*/

.CustomTabs .tab-content {
  margin: 10px auto 0 auto;
  display:  flex;
  flex-direction: column;
  position: initial;
  top: 0px; bottom: 0; left: 0; right: 0;
}

.tab-content-empty-text {
  margin: 10px 0px;
  font-style: italic;
  color: var(--color-dark-grey);
}

.CustomTabs .tab-list-item:nth-child(n+2) {
  margin: 0 0 0 5px;
}

.CustomTabs .tab-content-wrapper {
  margin: 0px auto 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  initial;
  margin-bottom:  10px;
}
.CustomTabs .tab-content {
  margin: 10px 0;
}


  .DataTableWrapper {
    position:  initial;
    width: 100%;
  }
  .DataTableWrapper .empty-text {
    margin: 15px 20px;
  }

  .DataTable {
    position:  initial;
    width: 100%;
  }

  .DataTable th, 
  .DataTable td {
    padding: 10px 5px;
  }

  .DataTable td:last-child,
  .DataTable th:last-child {
    padding-right: 0 !important;
  }


  .DataTable .type-photo {
    padding-right: 5px !important;
  }

  .DataTable .last-column {
    display: none;
  }

  .DataTable .prio-2 {
    display:  none;
  }

  .main-toolbar-item.main-button {
    margin-right: 0 !important;
  }
  .main-toolbar-title,
  .main-toolbar-text {
    display:  none;
  }



  .main-content .columns {
    flex-direction: column;
    justify-content: flex-start;

  }


  .MainColumn {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .MainColumn.column {
    padding-left: 20px !important;
  }

.MainColumn .button-back {
  
  top: 7px;
  left: 10px;
  
}

  .MainColumn .button-toggle-main-column {
    display:  none;
  }
  


  .ObjectDetails {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .ObjectDetails {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .menu .menu-item {
    padding: 0 10px !important;
  }
  .menu .menu-item.last {
    padding-right: 0 !important;
  }
  .menu .menu-item .menu-button .user-name::after {
    display: none !important;
  }

  .navbar {
    width: 100vw !important;
    flex-grow: 1;
    max-width: 100vw !important;
    width: 100vw;
    height: 60px;
    flex-grow: initial;
    flex-direction: row;
    align-items: center;
    bottom: 0;
    z-index:  100;
    padding: 0;
    border-top: 1px solid var(--color-light-grey);
  }

  .navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  }
  .nav-link {
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;
    padding: 5px 0;
    height: 100%;
    border-radius: 0;
  }
  .nav-link span {
    font-size: 0.6em;
    font-family: Rubik-Regular;
    margin: 5px 0 0 0;
    width:  100%;
    text-align: center;
  }

  .nav-link .nav-link-notification {
    height: 18px;
    min-width: 18px;
    top: 5px;
    right: calc(50% - 18px - 12px - 1px);
  }

  .nav-link .nav-link-notification span {
  font-size: 0.9em;
  margin: 0;
  }

  .navbar-group-title {
    display:  none;
  }
  .navbar-footer {
    display:  none;
  }

  .large-nav-links {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    margin: 0 0 3vh 0;
  }

  .large-nav-links-title {
    display:  none;
  }

  .large-nav-link-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    margin: 0 0 0 0;
  }

  .large-nav-link-icon-container {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .large-nav-link-subtitle {
    margin-bottom: 0;
  }

  .large-nav-link-block {
    flex-grow:  1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: initial;
    margin: 3vh 15px 0 15px;
    padding: 0;
  }

  .large-nav-link {
    flex-grow:  4;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: initial;
    margin: 3vh 15px 0 15px;
    padding: 0 30vw 0 10vw;
  }

  .wide-margin {
    margin: 10px 15px !important;
  }

  .message-container {
    width: 95vw;
    margin-left: calc(50% - 47.5vw);
  }

  .modal-container {
    max-height: 90vh;
    max-width: 95vw;
    min-width: 95vw;
  }

  .form {
    width: 100%;
  }
  .form-content {
    width: 100%;
  }
}